const blogParseDescription = (value) => {
  if (!value) {
    value = ''
  }
  value = value.replace(/(<p[^>]+?>|<p>)/gm, '')
  value = value.replace(/(<\/p>)/gm, '<br>')

  value = value.replace(/(\r\n|\n|\r)/gm, '<br>')

  //REMOVE TRAILING BR
  while (value.substring(value.length - 4) == '<br>') {
    value = value.substring(0, value.length - 4)
  }

  // console.log(value);
  return value.replace('[&hellip;]<br>', '...').replace('&#8211;', '-').replace('&#8217;', "'").replace('<br>', '')
}

const parseDescription = (value) => {
  if (!value) {
    value = ''
  }
  value = value.replace(/(<p[^>]+?>|<p>)/gm, '')
  value = value.replace(/(<\/p>)/gm, '<br>')

  value = value.replace(/(\r\n|\n|\r)/gm, '<br>')

  //REMOVE TRAILING BR
  while (value.substring(value.length - 4) == '<br>') {
    value = value.substring(0, value.length - 4)
  }

  // console.log(value);
  return value.replace('[&hellip;]<br>', '...').replace('&#8211;', '-').replace('&#8217;', "'")
}

const parseDescriptionAndUrl = (value) => {
  if (!value) {
    value = ''
  }
  value = value.replace(/(<a[^>]+?>|<a>)/gm, '')
  value = value.replace(/(<\/a>)/gm, '')
  value = value.replace(/(<p[^>]+?>|<p>)/gm, '')
  value = value.replace(/(<\/p>)/gm, '<br>')

  value = value.replace(/(\r\n|\n|\r)/gm, '<br>')

  //REMOVE TRAILING BR
  while (value.substring(value.length - 4) == '<br>') {
    value = value.substring(0, value.length - 4)
  }

  // console.log(value);
  return value.replace('[&hellip;]<br>', '...').replace('&#8211;', '-').replace('&#8217;', "'")
}

const parseUrl = (value) => {
  if (!value) {
    value = ''
  }
  value = value.replace(/(<p[^>]+?>|<p>)/gm, '')
  value = value.replace(/(<\/p>)/gm, '<br>')

  value = value.replace(/(\r\n|\n|\r)/gm, '<br>')

  //REMOVE TRAILING BR
  while (value.substring(value.length - 4) == '<br>') {
    value = value.substring(0, value.length - 4)
  }

  // console.log(value);
  return value
}

const parseDescriptionToArray = (value) => {
  if (!value) {
    value = ''
  }
  value = value.replace(/(<p[^>]+?>|<p>)/gm, '')
  value = value.replace(/(<\/p>)/gm, '<br>')

  value = value.replace(/(\r\n|\n|\r)/gm, '<br>')

  //REMOVE TRAILING BR
  while (value.substring(value.length - 4) == '<br>') {
    value = value.substring(0, value.length - 4)
  }

  // console.log(value);
  return value.split('<br>')
}

const getFirstItemFromClass = (arrayObj, classStr) => {
  return arrayObj.filter((x) => x.class == classStr)[0]
}

const getAllItemsFromClass = (arrayObj, classStr) => {
  return arrayObj.filter((x) => x.class == classStr)
}

const getFeaturedBlogs = (arrayBlog) => {
  return arrayBlog.filter((x) => x.node.featured == true)
}

const getBlogPosts = (contentfulPosts) => {
  let returnBlogs = []
  const uniqueSlugs = [...new Set(contentfulPosts.map((item) => item.node.slug))]
  uniqueSlugs.forEach((slug) => {
    returnBlogs.push(contentfulPosts.filter((x, index) => x.node.slug == slug)[0])
  })
  return returnBlogs
}

const getUrlPath = (fullurl) => {
  let urlString = `${fullurl}`
  if (!urlString.startsWith('https://')) urlString = `https://${urlString}`
  const url = new URL(`${urlString}`)
  return url.pathname
}

const getUrlPathSpecial = (fullurl) => {
  //for imageengine wp images
  return '//' + fullurl
}

//exports
module.exports = {
  getUrlPath,
  getUrlPathSpecial,
  parseDescription,
  parseDescriptionAndUrl,
  getFirstItemFromClass,
  getAllItemsFromClass,
  parseDescriptionToArray,
  parseUrl,
  getFeaturedBlogs,
  getBlogPosts,
  blogParseDescription,
}
